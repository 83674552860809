// eslint-disable-next-line no-unused-vars
const medical = [
  {
    type: 'input',
    name: 'patient_number',
    label: '住院编号',
    rules: [
      {required: true, message: '请输入住院编号', trigger: 'blur'},
    ],
    main: true,
  },
  {
    type: 'radio',
    name: 'sex',
    label: '患者性别',
    rules: [
      {required: true, message: '请选择患者性别', trigger: 'change'},
    ],
    options: [{
      label: '男',
      value: 1,
    }, {
      label: '女',
      value: 2
    },]
  },
  {
    type: 'input',
    name: 'age',
    label: '患者年龄',
    rules: [
      {required: true, message: '请输入患者年龄', trigger: 'blur'},
    ],
  },
  {
    type: 'input',
    name: 'height',
    label: '患者体重',
    rules: [
      {required: true, message: '请输入患者体重', trigger: 'blur'},
    ],
  },
  {
    type: 'date',
    name: 'admission_time',
    label: '入院时间',
    rules: [
      {required: true, message: '请选择入院时间', trigger: 'change'}
    ],
  },
  {
    type: 'textarea',
    name: 'previous_hostory',
    label: '既往史（有拍照内容即可不需要填写）',
    // rules: [
    //   {required: true, message: '请输入既往史', trigger: 'blur'}
    // ],
  },
  {
    type: 'input',
    name: 'diagnostic_results',
    label: '诊断结果',
    rules: [
      {required: true, message: '请输入诊断结果', trigger: 'blur'},
    ],
  },
  {
    type: 'radio',
    name: 'ndz_qsofa',
    label: '脓毒症qSOFA是否予以评估',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },
    ],
    rules: [
      {required: true, message: '请选择脓毒症qSOFA是否予以评估', trigger: 'change'}
    ],

  },

  {
    type: 'check',
    name: 'ndz_qsofa_detail',
    parent:true,
    parent_name:'ndz_qsofa',
    options: [
      {
        label: '呼吸频率≥22次/分',
      }, {
        label: '意识改变',
      }, {
        label: '收缩压≤100 mmHg）',
      },],
    rules: [
      {required: true, message: '请选择', trigger: 'change'}
    ],
  },

  {
    type: 'radio',
    name: 'sofa',
    label: 'SOFA是否予以评估',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择SOFA是否予以评估', trigger: 'change'}
    ],
  },
  {
    type: 'input',
    name: 'sofa_deatil',
    parent:true,
    parent_name:'sofa',
    label: '',
    placeholder: '评分较基线上升几分',
    rules: [
      {required: true, message: '评分较基线上升几分', trigger: 'change'}
    ],
  },
  {
    type: 'radio',
    name: 'infection_symptoms',
    label: '患者是否存在“感染”症状 ',
    rules: [
      {required: true, message: '请选择', trigger: 'change'}
    ],
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },


  {
    type: 'input',
    name: 'hr',
    label: '心率HR(次/分)',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'systolic_pressure',
    label: '收缩压(mmHg) ',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'diastolic_pressure',
    label: '舒张压(mmHg) ',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'arterial_pressure',
    label: '平均动脉压MAP(mmHg) ',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'temperature',
    label: '体温T(℃)',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'pulse',
    label: '脉搏P（次/分）',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },
  {
    type: 'input',
    name: 'breathe',
    label: '呼吸R（次/分）',
    placeholder: '请输入初始检查结果',
    rules: [
      {required: true, message: '请输入进入ICU时初始检查结果', trigger: 'blur'}
    ],
  },

  {
    type: 'radio',
    name: 'is_operation',
    label: '是否手术',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择是否手术', trigger: 'change'}
    ],
  },
  {
    type: 'input',
    name: 'operation_time',
    label: '手术时长（分钟）',
    placeholder: '请输入手术时长',
    parent: true,
    parent_name: 'is_operation',
    rules: [
      {required: true, message: '请输入手术时长（分钟）', trigger: 'blur'}
    ],
  },
]
const prescribed = [
  {
    type: 'input',
    name: 'asle_use_timing',
    label: '艾司洛尔使用时机',
    placeholder: '请输入液体复苏几小时后开始使用艾司洛尔',
    hide: true,
    rules: [
      {required: true, message: '请输入液体复苏几小时后开始使用艾司洛尔', trigger: 'blur'}
    ],
  },
  {
    type: 'radio',
    name: 'is_use_pressor',
    label: '是否使用升压药',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择是否使用', trigger: 'change'},
    ]
  },
  {
    type: 'dateTime',
    name: 'start_time',
    label: '艾司洛尔使用起始时间',
    rules: [
      {required: true, message: '艾司洛尔使用起始时间', trigger: 'change'},
    ]
  },
  {
    type: 'dateTime',
    name: 'end_time',
    label: '艾司洛尔使用结束时间',
    rules: [
      {required: true, message: '艾司洛尔使用结束时间', trigger: 'change'},
    ]
  },
  {
    type: 'radio',
    name: 'asle_use_method',
    label: '艾司洛尔使用方法',
    options: [{
      label: '持续不间断使用',
      value: 0,
    }, {
      label: '持续间断使用',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择使用方法', trigger: 'change'},
    ]
  },


  {
    type: 'input',
    name: 'dose_range',
    label: '艾司洛尔使用剂量范围(mg/h)',
    placeholder: '请输入使用剂量范围（mg/h）',
    sHolder: '最小剂量（mg/h）',
    eHolder: '最大剂量（mg/h）',
    double: true,
    rules: [
      {required: true, message: '请输入使用剂量范围（mg/h）', trigger: 'blur'},
    ]
  },
  {
    type: 'input',
    name: 'total',
    label: '艾司洛尔使用总量(mg)',
    placeholder: '请输入使用总量（mg）',
    rules: [
      {required: true, message: '请输入使用总量（mg）', trigger: 'blur'},
    ],

  },


]
//病程记录
const course = [




  {
    type: 'group',
    name: 'bp',
    label: '血压 (mmHg)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'bp_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'bp_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'bp_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'bp_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'bp_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'bp_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'hr',
    label: '心率 (次/分)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'hr_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'hr_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'hr_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'hr_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'hr_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'hr_6',
        placeholder: '请输入',

      },
    ],
  },
  {
    type: 'group',
    name: 'temperature',
    label: '体温 (℃)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'temperature_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'temperature_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'temperature_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'temperature_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'temperature_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'temperature_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'xyndjybhd',
    label: '吸氧浓度及氧饱和度 (%)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'xyndjybhd_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'xyndjybhd_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'xyndjybhd_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'xyndjybhd_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'xyndjybhd_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'xyndjybhd_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'cvp',
    label: 'CVP(mmHg)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'cvp_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'cvp_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'cvp_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'cvp_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'cvp_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'cvp_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'APACHII',
    label: 'APACHII（分）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'APACHII_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'APACHII_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'APACHII_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'APACHII_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'APACHII_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'APACHII_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'MAP',
    label: 'MAP(mmHg)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'MAP_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'MAP_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'MAP_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'MAP_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'MAP_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'MAP_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'PCT',
    label: 'PCT（ng/mL）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'PCT_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'PCT_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'PCT_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'PCT_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'PCT_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'PCT_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'CRP',
    label: 'CRP（mg/L）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'CRP_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'CRP_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'CRP_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'CRP_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'CRP_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'CRP_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'nl',
    label: '尿量（mL）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'nl_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'nl_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'nl_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'nl_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'nl_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'nl_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'jgz',
    label: '肌酐值（mg/dL）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'jgz_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'jgz_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'jgz_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'jgz_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'jgz_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'jgz_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'xghxywzljjl',
    label: '血管活性药物种类及剂量（μg/kg/min）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'xghxywzljjl_1',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'xghxywzljjl_2',
        placeholder: '请输入',
        rules: [
          {required: true, message: '请输入', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'xghxywzljjl_3',
        placeholder: '请输入',
      },
      {
        label: '使用后48h',
        name: 'xghxywzljjl_4',
        placeholder: '请输入',

      },
      {
        label: '使用后72h',
        name: 'xghxywzljjl_5',
        placeholder: '请输入',

      },
      {
        label: '使用后96h',
        name: 'xghxywzljjl_6',
        placeholder: '请输入',

      },
    ],

  },
  {
    type: 'group',
    name: 'Gap',
    label: 'Gap值 (mmHg)',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    remark: 'Gap=静脉血二氧化碳分压-动脉血二氧化碳分压',
    arr: [
      {
        label: '使用前24h',
        name: 'Gap_1',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'Gap_2',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'Gap_3',
        placeholder: '如未检测请填“无”',
      },
      {
        label: '使用后48h',
        name: 'Gap_4',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后72h',
        name: 'Gap_5',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后96h',
        name: 'Gap_6',
        placeholder: '如未检测请填“无”',

      },
    ],

  },
  {
    type: 'group',
    name: 'SV',
    label: 'SV（mL）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    remark: 'SV=心脏每搏输出量/心率*1000',
    arr: [
      {
        label: '使用前24h',
        name: 'SV_1',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'SV_2',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'SV_3',
        placeholder: '如未检测请填“无”',
      },
      {
        label: '使用后48h',
        name: 'SV_4',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后72h',
        name: 'SV_5',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后96h',
        name: 'SV_6',
        placeholder: '如未检测请填“无”',

      },
    ],

  },
  {
    type: 'group',
    name: 'CI',
    label: 'CI（L/(min·m2)）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    remark: 'CI=心脏每搏输出量x3600/身高(cm)*体重(kg)',
    arr: [
      {
        label: '使用前24h',
        name: 'CI_1',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'CI_2',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'CI_3',
        placeholder: '如未检测请填“无”',
      },
      {
        label: '使用后48h',
        name: 'CI_4',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后72h',
        name: 'CI_5',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后96h',
        name: 'CI_6',
        placeholder: '如未检测请填“无”',

      },
    ],

  },
  {
    type: 'group',
    name: 'IL',
    label: 'IL-6（pg/mL）',
    tips: '注：使用后24h、48h、72h、96h至少填写一个',
    placeholder: '请输入使用剂量范围（mg/h）',
    arr: [
      {
        label: '使用前24h',
        name: 'IL_1',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用时0h',
        name: 'IL_2',
        placeholder: '如未检测请填“无”',
        rules: [
          {required: true, message: '如未检测请填“无”', trigger: 'blur'},
        ]
      },
      {
        label: '使用后24h',
        name: 'IL_3',
        placeholder: '如未检测请填“无”',
      },
      {
        label: '使用后48h',
        name: 'IL_4',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后72h',
        name: 'IL_5',
        placeholder: '如未检测请填“无”',

      },
      {
        label: '使用后96h',
        name: 'IL_6',
        placeholder: '如未检测请填“无”',

      },
    ],

  },
  {
    type: 'radio',
    name: 'is_adverse_reaction',
    label: '不良反应',
    rules: [
      {required: true, message: '请选择不良反应', trigger: 'change'}
    ],
    options: [{
      label: '无',
      value: 0
    }, {
      label: '有',
      value: 1
    },]
  },
  {
    type: 'input',
    name: 'adverse_reaction_detail',
    parent:true,
    parent_name:'is_adverse_reaction',
    placeholder: '请描述具体情况及措施',
    rules: [
      {required: true, message: '请描述具体情况及措施', trigger: 'blur'}
    ],
  },

  {
    type: 'radioPicker',
    name: 'patient_transition',
    label: '患者结局',
    rules: [
      {required: true, message: '请选择患者结局', trigger: 'change'}
    ],
    options: [{
      label: '出院',
      value: 0
    }, {
      label: '死亡',
      value: 1
    },],
    arr:[
      {
        name:'leave_icu_date',
        placeholder:'请选择出ICU时间',
        parentName:'出院',
        rules: [
          {required: true, message: '请输入', trigger: 'change'},
        ]
      },
      {
        name:'leave_hospital_date',
        placeholder:'请选择出院时间',
        parentName:'出院',
        rules: [
          {required: true, message: '请输入', trigger: 'change'},
        ]
      },
      {
        name:'leave_icu_date',
        placeholder:'请选择出ICU时间',
        parentName:'死亡',
        rules: [
          {required: true, message: '请输入', trigger: 'change'},
        ]
      },

      {
        name:'death_date',
        placeholder:'请选择死亡时间',
        parentName:'死亡',
        rules: [
          {required: true, message: '请输入', trigger: 'change'},
        ]
      }
    ],

  },
]

export {
  medical,
  prescribed,
  course
}
