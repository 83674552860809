<template>
  <div class="home">
    <div class="content">
      <icuForm v-if="sonRefresh" :formData="formData" :ruleFormData="ruleFormData" type="progress_note" ref="child"
               @returnData="returnData" :isSelect="isSelect" :equipment="equipment" @examine="examine" :examineStatus="examineStatus" :auditStatus="auditStatus"></icuForm>
    </div>

    <div class="save-wrap">
      <div class="save-tips">注：请将信息填写完整后保存</div>
      <el-button type="" class="save-btn" @click="tsSaveClick" v-if="isSelect == 'false'">暂存</el-button>
      <el-button type="" class="save-btn ts-btn" @click="saveClick" v-if="isSelect == 'false'">保存</el-button>
      <el-button type="" class="save-btn" @click="selectClick" v-if="isSelect == 'true'">选择模板</el-button>
    </div>

  </div>
</template>


<script>
import wx from 'weixin-js-sdk'
import {medical, course,prescribed} from '@/database/medicalPyemia'

import icuForm from '@/components/icu-form-new'
import icuDialog from '@/components/icu-dialog'
import {getQueryVariable} from "@/libs/util";

export default {
  name: 'case',
  components: {
    icuForm,
  },
  data() {
    return {
      formData: {
        resident_admit_note: {title: '入院记录', list: medical},
        prescribed_medication: {title: '医嘱用药', list: prescribed},
        progress_note: {title: '病程记录+化验结果', list: course},
      },
      activeName: '1',
      progressForm: {},
      icuWeChat: {},
      submitData: {},
      isSelect: false,
      sonRefresh: true,
      ruleFormData: {},
      equipment:true,  //true安卓设备
      examineStatus:false,
      coding_image_list:[],
      auditStatus:'',
    }
  },
  created() {
    let icuWeChat = {};
    icuWeChat.created_by = getQueryVariable('created_by');
    icuWeChat.template_id = parseInt(getQueryVariable('template_id'));
    icuWeChat.patient_id = parseInt(getQueryVariable('patient_id'));
    icuWeChat.template_link = decodeURIComponent(getQueryVariable('template_link'));
    this.isSelect = getQueryVariable('isSelect');

    let id = getQueryVariable('id');

    this.icuWeChat = icuWeChat;
    sessionStorage.setItem('icuWeChat', JSON.stringify(icuWeChat))

    Object.values(this.formData).forEach(item => {
      item.list.forEach(child => {
        item[child.name] = this.returnType(child.type);
        if(child.arr){
          child.arr.forEach(n => {
            item[n.name] = this.returnType(child.type)
          })
        }
        item.image_list = [];
      })
    })

    if (id) {
      this.id = id
      this.getCaseRecords();
    }

    // this.wxStartCode();
    this.getEquipment();
    /**
     * 智能机浏览器版本信息:
     */


  },
  methods: {

    getEquipment(){
      let browser={
        versions: function() {
          let u = navigator.userAgent, app = navigator.appVersion;
          return {//移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/)||!!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/(i[^;]+;)( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
            iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
          };
        }(),
        language:(navigator.browserLanguage || navigator.language).toLowerCase()
      }

      // || browser.versions.iPhone || browser.versions.iPad
      if( browser.versions.android) {
        this.equipment = true
      }else{
        this.equipment = false
      }
    },
    //获取病历详情信息
    getCaseRecords() {
      let query = {
        created_by: this.icuWeChat.created_by
      }
      console.log(query)
      this.$ajaxApi.dataApi.getCaseRecords(this.id, query)
          .then(res => {
            console.log(res.data.data.template_content)
            if (res.data.status_code === 200) {
              let template_content = res.data.data.template_content;
              Object.keys(template_content).forEach(n => {
                Object.keys(template_content[n]).forEach(key => {
                  if (key === 'dose_range' || key === 'hr' || key === 'bp' || key === 'temperature' || key === 'serum_creatinine' || key === 'Bnp' || key === 'TnI') {
                    // console.log(progress_note[key])
                    template_content[n][key + '_STA'] = ''
                    template_content[n][key + '_END'] = ''

                    if (template_content[n][key]) {
                      let arr = template_content[n][key].split('-');
                      template_content[n][key + '_STA'] = arr[0] || '';
                      template_content[n][key + '_END'] = arr[1] || '' ;
                    }

                  }

                  if (key === 'APACHII' || key === 'CI' || key === 'CRP' || key === 'Gap' || key === 'IL' || key === 'MAP' || key === 'PCT' || key === 'SV' || key === 'bp' || key === 'cvp' || key === 'hr' || key === 'jgz' || key === 'nl' || key === 'temperature' || key === 'xghxywzljjl' || key === 'xyndjybhd') {
                    // console.log('key===>',key)
                    // console.log(template_content[n][key])
                    let arr = template_content[n][key].split('-');
                    // console.log(arr)
                    arr.forEach((c,i) => {
                      // template_content[n][key + '_'+ (i + 1)] = (c === '无' ? '' : c)
                      template_content[n][key + '_'+ (i + 1)] = c
                    })
                    // template_content[n][key + '_STA']
                  }
                })

              })
              this._.merge(this.formData,res.data.data.template_content);
              console.log(this.formData)
              this.sonRefresh= false;
              this.examineStatus = true;
              this.$nextTick(() => {
                this.sonRefresh= true;
              });
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    //选择模板
    selectClick() {

      let data = {
        created_by: this.icuWeChat.created_by,
        template_id: this.icuWeChat.template_id,
      }
      this.$ajaxApi.dataApi.myTemplates(data)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              // this.$message.success('提交成功');
              // wx.miniProgram.redirectTo({
              //   url: '../patientDetail/patientDetail?patient_id=' + this.icuWeChat.patient_id + '&callBack=true'
              // })
              this.$message.success('选择成功');
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1,
                })
              }, 800)
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    //form提交返回值
    returnData(data) {
      let copyList = []
      Object.values(data).map(item => {
        delete item.list
        Object.keys(item).forEach(n=> {
          if(n.indexOf('STA') !== -1 || n.indexOf('END') !== -1){
            delete item[n]
          }
          if(n.indexOf('_1') !== -1 || n.indexOf('_2') !== -1 || n.indexOf('_3') !== -1 || n.indexOf('_4') !== -1 || n.indexOf('_5') !== -1 || n.indexOf('_6') !== -1){
            delete item[n]
          }
          if(n === 'image_list'){
            item[n].forEach(c => {
              copyList.push(c)
            })
          }
        })
      })
      console.log(copyList)
      this.coding_image_list = copyList;
      this.submitData = data;
      if(this.id){
        this.uploadCaseRecords();
      }else{
        this.caseRecords();
      }
    },
    saveClick() {
      this.auditStatus = '';
      this.$refs.child.submitForm();
    },
    tsSaveClick() {
      this.auditStatus = 'STAGED'
      this.$refs.child.submitForm();
    },
    //正常保存，获取保存数据，保存成功后返回上一页
    caseRecords() {
      let data = {
        ...this.icuWeChat,
        coding_image_list:this.coding_image_list,
        template_content: {...this.submitData},
      }

      if(this.auditStatus!==''){
        data.audit_status = this.auditStatus
      }
      this.$ajaxApi.dataApi.caseRecordsUN(data)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              this.$message.success('提交成功');
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1,
                })
              }, 800)
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    //有id的情况下进行更新
    uploadCaseRecords(){
      let data = {
        ...this.icuWeChat,
        coding_image_list:this.coding_image_list,
        template_content: {...this.submitData},
      }

      if(this.auditStatus!==''){
        data.audit_status = this.auditStatus
      }
      this.$ajaxApi.dataApi.uploadCaseRecordsUN(this.id,data)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              this.$message.success('提交成功');
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1,
                })
              }, 800)
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },


    returnType(value) {
      let type;
      switch (value) {
        case 'input':
          type = '';
          break;
        case 'select':
          type = '';
          break;
        case 'check':
          type = [];
          break;
        case 'radio':
          type = '';
          break;
        case 'textarea':
          type = '';
          break;
        case 'date':
          type = '';
          break;
        case 'dateTime':
          type = '';
          break;
        case 'hidden':
          type = '';
          break;
        case 'radioPicker':
          type = '';
          break;
        case 'group':
          type = '';
          break;
      }
      return type;
    },

    examine(value){
      console.log(value)
      let data = {
        patient_number: value,
        template_id: this.icuWeChat.template_id,
      }
      this.$ajaxApi.dataApi.caseRecordsCheck(data)
        .then(res => {
          if (res.data.status_code === 200) {
            if(res.data.data){
              if(res.data.data.check !== 'pass'){
                this.$message.error(res.data.data.msg)
              }else{
                this.$message.success(res.data.data.msg)

              }
            }else {
              this.$message.success(res.data.message)
            }
            this.examineStatus = true;
          } else {
            this.$message.error(res.data.message);
            this.examineStatus = false;
          }
        })
        .catch(error => {
          console.log(error)
        })
    },



  },

}
</script>

<style scoped>
.home {
  padding-bottom: 100px;
}

.content {
  padding: 0 18px;
}

.home .collapse-wrap /deep/ .el-collapse-item__header {
  /*padding: 0 25px;*/
  color: #030303;
  font-size: 16px;
  border-bottom-color: #F7F9FA;
}

.save-wrap {
  position: fixed;
  padding: 4px 0 11px;
  width: 100%;
  background-color: #FFF;
  bottom: 0;
  border-top: 1px solid #CFCFCF;
  z-index: 999;

}

.save-tips {
  font-size: 14px;
  color: #030303;
  padding: 5px 0;
}

.save-btn {
  padding: 9px 64px;
  color: #44A4E6;
  font-size: 16px;
  border: 1px solid #44A4E6;
}
.ts-btn {
  color: #FFFFFF;
  background: #44A4E6;
  border: 1px solid #44A4E6;
}
</style>

